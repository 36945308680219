function initGTM() {
  if (window.isGTMLoaded) {
      return false;
  }
  window.dataLayer = window.dataLayer || [];
  window.gtag = function() { window.dataLayer.push(arguments);};
  window.isGTMLoaded = true;
  const GTM_ID = "GTM-MKQGRGC";
  const script = document.createElement("script");
  const noScript = document.createElement("noscript");
  // noscript is used as a backup if user has js disabled
  noScript.innerHTML = `
  <iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}" height="0" width="0" style="display: none; visibility: hidden" aria-hidden="true"></iframe>
  `;
  script.innerHTML = `
  window.dataLayer = window.dataLayer || [];window.gtag = function() { window.dataLayer.push(arguments);};window.dataLayer.push({"platform":"gatsby"}); (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl+'';f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer', 'GTM-MKQGRGC');
  `;
  document.head.appendChild(script);
  document.body.insertBefore(noScript, document.body.firstChild);
}

function loadGTM(event) {
  initGTM();
  event.currentTarget.removeEventListener(event.type, loadGTM);
}

export const onClientEntry = () => {
  document.onreadystatechange = () => {
      if (document.readyState !== "loading") {
          setTimeout(initGTM, 3500);
      }
  };
  document.addEventListener("scroll", loadGTM);
  document.addEventListener("mousemove", loadGTM);
  document.addEventListener("touchstart", loadGTM);
  document.addEventListener('DOMContentLoaded', loadGTM);
};